.map {
	height: 300px;
	max-width: 100vw;
	background: gray;
	#map {
		height: 300px;
		width: 100%;
	}
	.small {
		font-size: $font-size-small;
	}
	.x-small {
		font-size: 12px;
	}
}