@import url(https://fonts.googleapis.com/css?family=Roboto:400,400i,500,700|IM+Fell+English+SC|Lobster);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }
  table td {
    border-radius: 0 !important; }

img {
  display: block; }

* {
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  font-family: "Roboto", "Arial", sans-serif;
  line-height: 1.5em; }

.aside {
  padding: 0 1rem;
  color: grey;
  font-style: italic;
  text-align: center;
  margin: 3rem 0; }

aside {
  padding: 0;
  text-decoration: none;
  font-size: 20px;
  font-weight: 400;
  color: grey; }

aside a {
  text-decoration: none;
  font-size: 20px;
  font-weight: 700;
  color: grey; }

body#body.no-scroll {
  overflow: hidden;
  position: static;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0; }

#root .App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden; }

.content {
  margin: 0rem; }
  @media (min-width: 900px) {
    .content {
      max-width: calc(100vw - 4rem);
      width: 100%;
      margin: 2rem auto 0 auto; } }

.static_content {
  margin-bottom: 2rem; }

button {
  width: 100%;
  outline: none;
  background: none;
  border: none;
  font: 400 16px "Roboto", "Arial", sans-serif;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  color: grey; }

button:disabled {
  font-weight: 500;
  color: grey;
  background: #ccc; }

.mainHeader {
  position: relative;
  display: flex; }

.mainHeader__carouselContainer {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 100vw; }
  @media (min-width: 431px) {
    .mainHeader__carouselContainer {
      height: 64.5vw; } }
  @media (min-width: 801px) {
    .mainHeader__carouselContainer {
      height: 28vw; } }
  .mainHeader__carouselContainer img {
    min-height: 100%;
    min-width: 100vw;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    position: absolute;
    opacity: 0;
    transition: opacity 1s linear; }
  .mainHeader__carouselContainer img.active {
    z-index: 0;
    opacity: 1;
    transition: opacity 1s linear; }

.mainHeader__logo {
  position: absolute;
  width: 150px;
  height: 150px;
  -webkit-filter: drop-shadow(0 0 30px rgba(0, 0, 0, 0.9));
          filter: drop-shadow(0 0 30px rgba(0, 0, 0, 0.9));
  right: 1rem;
  top: 1rem; }
  @media (min-width: 431px) {
    .mainHeader__logo {
      width: 150px;
      height: 150px;
      right: 1rem; } }
  @media (min-width: 801px) {
    .mainHeader__logo {
      width: 200px;
      height: 200px;
      right: 2rem;
      top: unset;
      align-self: center; } }

.mainHeader__carouselControl {
  height: 50px;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center; }
  .mainHeader__carouselControl div {
    padding: 15px;
    align-self: center;
    cursor: pointer; }
    @media (min-width: 801px) {
      .mainHeader__carouselControl div {
        margin: 5px; } }
    .mainHeader__carouselControl div:after {
      display: block;
      content: "";
      width: 10px;
      height: 10px;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 50%; }
  .mainHeader__carouselControl div.active:after {
    background: rgba(255, 255, 255, 0.8); }

.mainHeader__slideTitle {
  width: 100%;
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  position: absolute;
  bottom: 3rem;
  text-align: center;
  font: 40px 'IM Fell English SC';
  opacity: 0;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  transition: opacity 300ms linear, -webkit-transform 300ms linear;
  transition: opacity 300ms linear, transform 300ms linear;
  transition: opacity 300ms linear, transform 300ms linear, -webkit-transform 300ms linear; }
  @media (min-width: 431px) {
    .mainHeader__slideTitle {
      text-align: left;
      left: 2rem;
      font-size: 45px; } }
  .mainHeader__slideTitle.active {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
    transition: opacity 300ms linear; }

@-webkit-keyframes animate-skeleton {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.8; }
  100% {
    opacity: 1; } }

@keyframes animate-skeleton {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.8; }
  100% {
    opacity: 1; } }

.horizontal--skeleton {
  -webkit-animation: animate-skeleton 2s infinite;
          animation: animate-skeleton 2s infinite; }
  .horizontal--skeleton .tabs {
    display: flex; }
    .horizontal--skeleton .tabs li {
      padding: 1rem; }
      .horizontal--skeleton .tabs li:after {
        display: block;
        content: "";
        background: #eee;
        width: 100px;
        height: 1rem;
        border-radius: 0.5rem; }
      .horizontal--skeleton .tabs li:first-child {
        background: #eee; }
        .horizontal--skeleton .tabs li:first-child:after {
          background: white; }
  .horizontal--skeleton .posts {
    display: block;
    background: #eee;
    padding: 2rem 0; }
    .horizontal--skeleton .posts .subcats {
      display: flex;
      justify-content: center; }
      .horizontal--skeleton .posts .subcats li {
        background: white;
        width: 100px;
        height: 1rem;
        margin: 1rem;
        border-radius: 0.5rem; }
    .horizontal--skeleton .posts .post--area {
      display: flex;
      justify-content: center; }
      .horizontal--skeleton .posts .post--area li {
        width: 150px;
        height: calc(150px + 3rem);
        background: white;
        margin: 1rem;
        border-radius: 3px;
        overflow: hidden; }
        .horizontal--skeleton .posts .post--area li:before {
          display: block;
          content: "";
          width: 100%;
          padding-top: 100%;
          background: #ccc; }
        .horizontal--skeleton .posts .post--area li:after {
          display: block;
          content: "";
          border-radius: 0.5rem;
          height: 1rem;
          background: #eee;
          margin: 1rem; }

.vertical--skeleton {
  -webkit-animation: animate-skeleton 2s infinite;
          animation: animate-skeleton 2s infinite; }
  .vertical--skeleton .categories {
    display: flex;
    flex-direction: column; }
    .vertical--skeleton .categories > li {
      padding: 1rem 0;
      width: 100%;
      border-bottom: 1px solid #ccc; }
      .vertical--skeleton .categories > li:first-child {
        background: #eee; }
        .vertical--skeleton .categories > li:first-child:before {
          background: white; }
      .vertical--skeleton .categories > li:before {
        display: block;
        background: #eee;
        margin: 0.5rem auto;
        content: "";
        width: 100px;
        height: 1rem;
        border-radius: 0.5rem; }
  .vertical--skeleton .subcategories {
    display: flex;
    justify-content: center; }
    .vertical--skeleton .subcategories > li {
      padding: 0;
      background: #ccc;
      width: 100px;
      height: 1rem;
      margin: 1rem;
      border-radius: 0.5rem; }
  .vertical--skeleton .posts {
    display: flex;
    justify-content: center; }
    .vertical--skeleton .posts li {
      background: white;
      width: 130px;
      height: calc(130px + 2rem);
      margin: 1rem !important;
      border-radius: 3px;
      overflow: hidden;
      padding: 0; }
      .vertical--skeleton .posts li:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 100%;
        background: #ccc; }
      .vertical--skeleton .posts li:after {
        display: block;
        content: "";
        margin: 0.5rem auto;
        background: #eee;
        border-radius: 0.5rem;
        height: 1rem;
        width: 100px; }

.categories {
  color: grey;
  text-transform: uppercase;
  display: flex; }

.categories.vertical {
  flex-direction: column;
  text-align: center; }
  .categories.vertical li {
    border-bottom: 1px solid #ccc; }
    .categories.vertical li.active {
      border-bottom: none;
      font-weight: 700; }

.categories li {
  padding: 1rem;
  cursor: pointer;
  display: block; }
  .categories li:last-child {
    margin-right: 0; }
  .categories li.active {
    font-weight: 700;
    background: #eee; }

.postsArea {
  background: #eee;
  padding: 4rem 1rem; }

.postsArea .subcategories {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  flex-wrap: wrap; }
  .postsArea .subcategories li {
    white-space: nowrap;
    margin: 0.5rem 0.5rem;
    cursor: pointer;
    border-bottom: none; }
    .postsArea .subcategories li.active {
      border-bottom: 2px solid grey; }

.vertical .postsArea {
  padding-top: 0; }

.vertical .postsArea .subcategories li {
  font-weight: 400;
  text-transform: none;
  color: black;
  padding: 0; }

.posts {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }

.post {
  background: white;
  margin: 0 0.5rem;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
  max-width: 130px;
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 3px;
  margin-bottom: 1rem;
  cursor: pointer; }
  .post img {
    max-height: 130px; }
  .post p {
    padding: 0.5rem;
    text-transform: none;
    color: black; }

.inlineCategory.hidden {
  -webkit-transform: scaleY(0);
          transform: scaleY(0); }

.inlineCategory {
  -webkit-transform-origin: top;
          transform-origin: top;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
  transition: -webkit-transform 300ms ease-out;
  transition: transform 300ms ease-out;
  transition: transform 300ms ease-out, -webkit-transform 300ms ease-out;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0) 40px), linear-gradient(to top, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0) 40px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 1rem;
  overflow: auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2) inset; }

.item.hidden {
  -webkit-transform: scale(0);
          transform: scale(0); }

.item {
  -webkit-transform: scale(1);
          transform: scale(1);
  padding: 0.5rem;
  background: white;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  transition: -webkit-transform 300ms ease-out;
  transition: transform 300ms ease-out;
  transition: transform 300ms ease-out, -webkit-transform 300ms ease-out; }
  .item img {
    padding-bottom: 5px; }
  .item p {
    text-align: center; }

.contact {
  padding: 0 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  position: relative; }
  @media (min-width: 900px) {
    .contact {
      flex-direction: row;
      justify-content: center;
      max-width: 1000px;
      margin: 0 auto 1rem auto; } }

.contact_text {
  align-self: center;
  padding-bottom: 3rem;
  text-align: center;
  border-bottom: 1px solid #ccc; }
  @media (min-width: 900px) {
    .contact_text {
      border-bottom: none;
      padding-bottom: 0;
      margin-right: 1rem; } }

.or {
  text-transform: lowercase;
  font-size: 30px;
  font-style: italic;
  font-family: 'Lobster', serif;
  background: white;
  width: 50px;
  min-width: 50px;
  height: 50px;
  max-height: 50px;
  box-sizing: border-box;
  border-radius: 50%;
  align-self: center;
  position: relative;
  text-align: center;
  color: rgba(128, 128, 128, 0.87);
  margin-top: -25px;
  line-height: 42px;
  padding-right: 4px;
  border: 1px solid grey; }
  @media (min-width: 900px) {
    .or {
      margin-top: 0;
      margin-right: -25px; } }

.contact_form {
  margin-top: 2rem;
  align-self: center;
  max-width: 500px;
  width: 100%; }
  @media (min-width: 900px) {
    .contact_form {
      padding-left: 3rem;
      border-left: 1px solid #ccc; } }

.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem; }
  .input label {
    margin-bottom: 0.25rem;
    text-transform: uppercase;
    font: bold 1rem 'Roboto', 'Arial', sans-serif;
    color: rgba(128, 128, 128, 0.87); }
  .input input {
    background: #eee;
    border: none;
    padding: 1rem;
    border-radius: 3px;
    outline: none; }

.textarea {
  margin-bottom: 1rem;
  line-height: 0; }
  .textarea label {
    margin-bottom: 0.25rem;
    text-transform: uppercase;
    font: bold 1rem 'Roboto', 'Arial', sans-serif;
    color: rgba(128, 128, 128, 0.87);
    display: block; }
  .textarea textarea {
    background: #eee;
    color: rgba(0, 0, 0, 0.87);
    resize: none;
    border: none;
    border-radius: 3px;
    width: 100%;
    outline: none;
    padding: 1rem;
    min-height: 8rem;
    box-sizing: border-box; }

@-webkit-keyframes showValidateMessage {
  0% {
    margin-top: -20px; }
  100% {
    margin-top: 0px; } }

@keyframes showValidateMessage {
  0% {
    margin-top: -20px; }
  100% {
    margin-top: 0px; } }

.validation_message {
  position: relative;
  z-index: -1;
  text-align: right;
  color: grey;
  font-size: 14px;
  margin-top: -20px;
  -webkit-animation: showValidateMessage 0.3s forwards;
          animation: showValidateMessage 0.3s forwards; }

.large_button {
  background: grey;
  color: rgba(255, 255, 255, 0.87);
  padding: 0 1rem 0 0;
  border-radius: 3px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  align-self: flex-end;
  display: flex;
  justify-content: flex-start; }

.large_button span {
  align-self: center;
  padding: 1rem 1rem 1rem 2rem; }

@-webkit-keyframes slide {
  0% {
    bottom: -6rem; }
  2% {
    bottom: 0rem; }
  98% {
    bottom: 0rem; }
  100% {
    bottom: -6rem; } }

@keyframes slide {
  0% {
    bottom: -6rem; }
  2% {
    bottom: 0rem; }
  98% {
    bottom: 0rem; }
  100% {
    bottom: -6rem; } }

.success_toast {
  position: fixed;
  bottom: -6rem;
  padding: 2rem 1rem;
  background: grey;
  color: rgba(255, 255, 255, 0.87);
  width: 100vw;
  left: 0;
  z-index: 9999;
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.3);
  -webkit-animation: slide 5s ease-in-out;
          animation: slide 5s ease-in-out; }

.reCaptcha {
  margin-bottom: 1rem; }

.contactServerError {
  color: red;
  font: 0.8rem 'Roboto', 'Arial', sans-serif;
  margin-bottom: 1rem;
  display: block; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg); } }

.spinnerContainer {
  width: 1.5rem;
  height: 1.5rem;
  transition: margin-left 0.5s ease-in-out, opacity 0.5s ease-in-out;
  position: relative;
  align-self: center;
  margin-left: -1.5rem;
  opacity: 0; }

.spinnerContainer.showSpinner {
  margin-left: 0;
  opacity: 1; }

.formSpinner {
  overflow: visible;
  width: 1.5rem;
  height: 1.5rem;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite; }

.map {
  height: 300px;
  max-width: 100vw;
  background: gray; }
  .map #map {
    height: 300px;
    width: 100%; }
  .map .small {
    font-size: 14px; }
  .map .x-small {
    font-size: 12px; }

.mainFooter {
  background: grey;
  color: #eee;
  padding: 2rem 1rem; }

.modal__scrim {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 1rem 1rem 2rem 1rem; }

@-webkit-keyframes spinRight {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinRight {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes spinLeft {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(-720deg);
            transform: rotate(-720deg); } }

@keyframes spinLeft {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(-720deg);
            transform: rotate(-720deg); } }

.spinner {
  align-self: center;
  display: flex;
  -webkit-animation: spinRight 1s linear infinite;
          animation: spinRight 1s linear infinite; }

@-webkit-keyframes expand {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0; }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }

@keyframes expand {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0; }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }

.modalContainer {
  align-self: center;
  position: relative;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5); }

.modal {
  align-self: center;
  background: white;
  border-radius: 3px;
  box-sizing: border-box;
  -webkit-animation: expand 200ms ease-out forwards;
          animation: expand 200ms ease-out forwards;
  overflow-y: auto;
  max-height: calc(100vh - 3rem);
  max-width: 700px;
  position: relative;
  display: table; }

.modal__close {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: black;
  position: absolute;
  z-index: 1;
  bottom: -15px;
  left: calc(50% - 15px);
  box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.3); }
  .modal__close:before, .modal__close:after {
    display: block;
    content: '';
    width: 4px;
    height: 20px;
    background: #eee;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    position: absolute;
    left: 13px;
    top: 5px; }
  .modal__close:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }

.modalText {
  padding: 1rem;
  display: table-caption;
  caption-side: bottom;
  background: white; }

.modalText__title {
  font: 700 16px "Roboto", "Arial", sans-serif;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  flex-shrink: 1; }

.modalText__materials {
  font-style: italic; }

.modalText__dimensions {
  font: 700 14px "Roboto", "Arial", sans-serif; }

.modal__gallery__full {
  max-width: 100%; }

.modal__gallery__thumbs {
  display: flex;
  padding: 1rem; }

.modalText__indicia {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid #ccc; }

.thumbnailContainer {
  width: 75px;
  height: 75px;
  margin-right: 0.5rem;
  cursor: pointer; }
  .thumbnailContainer:last-child {
    margin-right: 0; }

.modal__gallery__thumbs .thumbnail {
  width: 100%;
  height: 75px;
  object-fit: cover; }

