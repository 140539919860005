.modal__scrim {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(black, 0.4);
	display: flex;
	justify-content: center;
	box-sizing: border-box;
	padding: 1rem 1rem 2rem 1rem;
}

@keyframes spinRight {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes spinLeft {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(-720deg);
	}
}

.spinner {
	align-self: center;
	display: flex;
	animation: spinRight 1s linear infinite;
}

@keyframes expand {
	from {
		transform: scale(0);
		opacity: 0;
	}
	to {
		transform: scale(1);
		opacity: 1;
	}
}

.modalContainer {
	align-self: center;
	position: relative;
	box-shadow: 0 0 50px rgba(black, 0.5);
}

.modal {
	align-self: center;
	background: white;
	border-radius: 3px;
	box-sizing: border-box;
	animation: expand 200ms ease-out forwards;
	overflow-y: auto;
	max-height: calc(100vh - 3rem);
	max-width: 700px;
	position: relative;
	display: table;
}

.modal__close {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background: black;
	position: absolute;
	z-index: 1;
	bottom: -15px;
	left: calc(50% - 15px);
	box-shadow: 0 -2px 3px rgba(black, 0.3);
	&:before, &:after {
		display: block;
		content: '';
		width: 4px;
		height: 20px;
		background: $neutralLight;
		transform: rotate(45deg);
		position: absolute;
		left: 13px;
		top: 5px;
	}
	&:after {
		transform: rotate(-45deg);
	}
}

.modalText {
	padding: 1rem;
	display: table-caption;
	caption-side: bottom;
	background: white;
}

.modalText__title {
	font: $font-weight-bold $font-size-regular $font-family-text;
	text-transform: uppercase;
	margin-bottom: 0.5rem;
	flex-shrink: 1;
}

.modalText__materials {
	font-style: italic;
}

.modalText__dimensions {
	font: $font-weight-bold $font-size-small $font-family-text;
}

.modal__gallery__full {
	max-width: 100%;
}

.modal__gallery__thumbs {
	display: flex;
	padding: 1rem;
}

.modalText__indicia {
	margin-top: 0.5rem;
	padding-top: 0.5rem;
	border-top: 1px solid $neutral;
}

.thumbnailContainer {
	width: 75px;
	height: 75px;
	margin-right: 0.5rem;
	cursor: pointer;
	&:last-child {
		margin-right: 0;
	}
}

.modal__gallery__thumbs .thumbnail {
	width: 100%;
	height: 75px;
	object-fit: cover;
}