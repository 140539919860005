.inlineCategory.hidden {
	transform: scaleY(0);
}

.inlineCategory {
	transform-origin: top;
	transform: scaleY(1);
	transition: transform 300ms ease-out;
	background: linear-gradient(to bottom, rgba(black, 0.05), rgba(black, 0) 40px), linear-gradient(to top, rgba(black, 0.05), rgba(black, 0) 40px);
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	padding: 1rem;
	overflow: auto;
	box-shadow: 0 0 10px 0 rgba(black, 0.2) inset;
}

.item.hidden {
	transform: scale(0)
}

.item {
	transform: scale(1);
	padding: 0.5rem;
	background: white;
	box-shadow: 0 0 3px 1px rgba(black, 0.2);
	transition: transform 300ms ease-out;
	img {
		padding-bottom: 5px;
	}
	p {
		text-align: center;
	}
}