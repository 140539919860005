@mixin breakpoint($point) {
	@if $point == js-bear {
		@media (min-width: 900px)  {@content; }
	}
	@if $point == papa-bear {
		@media (min-width: 801px)  {@content; }
	}
	@if $point == mama-bear {
		@media (min-width: 431px)  {@content; }
	}
}

body#body.no-scroll {
	overflow: hidden;
	position: static;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}

#root .App {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	overflow: hidden;
}

.content {
	margin: 0rem;
	@include breakpoint(js-bear) {
		max-width: calc(100vw - 4rem);
		width: 100%;
		margin: 2rem auto 0 auto;
	}
}

.static_content {
	margin-bottom: 2rem;
}